<template>
    <UiButtonIcon class="relative"
                  color="basic"
                  size="small"
                  :aria-label="$t('accessibility.cart')"
    >
        <UiCountIndicator
            class="absolute translate-x-2.5 translate-y-2.5"
            color="danger"
            :count="totalAmount"
        />
        <IconShoppingBag class="h-[1.375rem] lg:h-[1.875rem]" aria-hidden />
    </UiButtonIcon>
</template>

<script lang="ts" setup>

const totalAmount = ref<number>(42)

</script>

<style lang="scss" scoped>

</style>
